import styledComponents from "styled-components";


const TestContainer = styledComponents.section`
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    perpective: 800px;

    div{
        width: 50px;
        height: 50px;
        background: red;
        transition: 0.5s;
    }
    
    .t01{
        opacity: 0;
    }

    input{
        display: none;
    }

    #checkId:checked + .t01{
        opacity:1;
    }

    #checkId2:checked + .t02{
        animation: 1s vai infinite alternate;
    }

    @keyframes vai{
        50%{
            transform: translateY(200px) rotateY(45deg)
        },
        75%{
            transform: translateY(-200px)
        },
        100%{
            transform: translateY(0)
        }
    }
`;

function PageTest(){

    return(
        <TestContainer>
            <label for="checkId" className="p1">
                TESTE 01
            </label>
            <input type="checkbox" id="checkId"/>
            <div className="t01"/>

            <label for="checkId2">TESTE 02</label>
            <input type="checkbox" id="checkId2"/>
            <div className="t02"></div>
        </TestContainer>
    )
}

export default PageTest;