import { useContext, useState, useEffect, useRef } from "react";
import { CardPerson, TeamBody, TeamBox1, TeamBox2, TeamContainer, TeamContent } from "./style";
import { LanguageContext } from "../../common/contexts/languageContext";
import contentJson from "./content.json";
import Header from "../../common/patters/header/index.js";
import Footer from "../../common/patters/footer/index.js";
import useSwitchPerson from "../../common/hooks/useSwitchPerson";
import {ReactComponent as PeopleTeam} from "../../images/peopleTeam.svg"



export default function PageTeam(){
    const {language} = useContext(LanguageContext);
    const [content, SetContent] = useState(contentJson[language]);
    const [personSelected, switchPerson] = useSwitchPerson();
    const person01 = useRef();
    const person02 = useRef();

    useEffect(()=>{
        SetContent(contentJson[language]);
    }, [language]);

    return(
        <TeamBody>
            <Header/>
            
            <TeamContainer>
                <div className="title">
                    <h2>{content.title}</h2>
                    <span className="underLine"> </span>
                </div>
                    <section className="flexContent">
                        <TeamBox1>
                            <CardPerson onClick={()=> switchPerson(person01)}>
                                <div className="img01" id="person01" ref={person01}/>
                                <h4>{content.person01.name}</h4>
                            </CardPerson>

                            <CardPerson onClick={()=> switchPerson(person02)}>
                                <div className="img02" id="person02" ref={person02}/>
                                <h4>{content.person02.name}</h4>
                            </CardPerson>
                        </TeamBox1>

                        {
                        !personSelected 
                        ? 
                        <PeopleTeam className="peopleTeam"/> 
                        :
                        <TeamBox2>
                            <h2 className="title2">{content[personSelected.id].name} - {content[personSelected.id].occupation}</h2>

                            <TeamContent className={personSelected.id}>
                                <div className="text">
                                    <p>{content[personSelected.id].cv}</p>
                                    <p>{content[personSelected.id].description}</p>
                                </div>
                            </TeamContent>
                        </TeamBox2>
                        }
                </section>
            </TeamContainer>
            
            <Footer/>
        </TeamBody>
    )
}