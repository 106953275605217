import styledComponents from "styled-components";
import { backgroundColor, primary, tabletScreenMaxWidth } from "../../common/foundation/variables";
import bgHistory from "../../images/history.webp";
import bgWind from "../../images/wind.jpg";
import bgOil from "../../images/oil.jpg";
import bgSolar from "../../images/solar.jpg";

export const CompanyBody = styledComponents.section`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;

    overflow-x: hidden;
    
    .bg{
        display: none;
    }

    .header, .footer{
        height: auto;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        .bg{
            display: block;
            position: fixed;
            top:0;
            left:0;
            width: 100vw;
            height:100vh;
            z-index: -1;
            transition: 0.5s;

            opacity: 0;
            animation: 2s fadeIn forwards; 
        }

        
        .history{
            background: url(${bgHistory}) center / cover no-repeat;

            ::before{
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height:100%;
                background: ${backgroundColor};
                opacity: 0.6;
            }
        }

        .oil{
            background: url(${bgOil}) center / cover no-repeat;
            
            ::before{
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height:100%;
                background: ${backgroundColor};
                opacity: 0.6;
            }
        }

        .wind{
            background: url(${bgWind}) bottom / cover no-repeat;

            ::before{
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height:100%;
                background: ${backgroundColor};
                opacity: 0.6;
            }
        }

        .sun{
            background: url(${bgSolar}) center / cover no-repeat;

            ::before{
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height:100%;
                background: ${backgroundColor};
                opacity: 0.7;
            }
        }
    }
`;


export const CompanyMain = styledComponents.main`
    padding: 2.5vh 5vw;
    height: 82.5vh;

    display: flex;
    flex-direction: column;
    
    justify-content: space-evenly;

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        justify-content: space-between;
    }

    `;
    
    export const CompanyMenu = styledComponents.div`
        width: 100%;
        height: 20%;

        opacity:0;
        animation: 1s showLeft forwards;
    
        .list{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            align-items: center;
            
            li{
                width: 35%;
                padding: 2.5% 0;
                margin-bottom: 2.5vh;
                background: rgba(0,0,0,0.25);
                border-radius: 5px;
                text-align: center;
                transition: 0.5s;
            }

            .select{
                background:${primary};
                color: #fff;
            }
        }
        @media screen and (min-width: ${tabletScreenMaxWidth}px){
            width: 20%;
            height: auto;

            order: 1;
            display: flex;
            align-items: center;
            justify-content: end;
            align-self: stretch;

            .list{
                width: auto;
                height: auto;
                padding: 5vh 2.5vw;
                min-height: 40vh;
                flex-direction: column;
                flex-wrap: no-wrap;
                align-items: center;
                background: rgba(0,0,0,0.25);
                border-radius: 5px;
            
                li{
                    width: auto;
                    padding: 0;
                    background: none;
                    border-radius: 0;
                    text-align: center;
                    cursor: pointer;
                    font-size: 1.5vw;
                }

                .select{
                    background: none;
                    color: #fff;
                    transform: scale(1.2);
                }
            }
        }
    
`;

export const CompanyContent = styledComponents.div`
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    opacity: 0;
    animation: 1s showUp forwards;

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        width: 60%;
        min-height: 75vh;
        align-items: start;
        
        .title{
            position: relative;
            margin-bottom: 5vh;
            
            h2{
                margin-bottom: 1vh;
            }
            
            .underLine{
                width: 60%;
                border-top: 2px solid #000000;
                position: absolute;
                bottom:0;
                left: 0.25vw;
            }
        }

    }
`;

