import styledComponents from "styled-components";
import { tabletScreenMaxWidth } from "../../common/foundation/variables";


export const EnvaironmentBody = styledComponents.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const EnvaironmentMain = styledComponents.main`
    width: 100%;
    min-height: 82.5vh;
    display:flex;
    flex-direction: column;
    padding: 2.5vh 5vw;

    .title{
        position: relative;
        text-align: left;
        
        h2{
            margin-bottom: 1vh;
            width: 0;
            white-space: nowrap;
            overflow: hidden;
            opacity: 0;
    
            animation: 2s write 0.25s steps(55) forwards;
        }
        
        .underLine{
            width: 30%;
            border-top: 2px solid #000000;
            position: absolute;
            bottom:0;
            left: 0.25vw;
        }
    }

`;

export const MainContent = styledComponents.section`
    width: 100%;
    margin-top: 2.5vh;

    p{
        opacity: 0;
        animation: 1s showRight forwards;
    }
    
    iframe{
        margin-top: 5vh;
        width:100%;
        min-height: 25vh;
        height: 100%;
        border-radius: 5px;
        box-shadow: 0 0 20px rgba(0,0,0,0.4)

        opacity: 0;
        animation: 1s showUp forwards;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        height: 62.5vh;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p{
            width: 50%;
        }

        iframe{
            margin-top: 0;
            width: 40%;
            min-height: 50vh;
            height: 80%;
        }
    }
`;