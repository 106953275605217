import { useState, createContext } from "react";


export const LanguageContext = createContext();


export default function LanguageProvider({children}){
    const [language, setLanguage] = useState("pt-br");

    return(
        <LanguageContext.Provider value={{
            language, 
            setLanguage
        }}>
            {children}
        </LanguageContext.Provider>
    )
}