import { memo, useState, useEffect } from "react";
import { PoupUpCard, PoupUpMain } from "./style";
import {ReactComponent as ConstructionSvg} from "../../../images/construction.svg"


function PoupUp(){
    const [showUp, setShowUp] = useState(false);
    useEffect(()=>{
        console.log(window.sessionStorage.getItem("alreadyShown"));

        if(!window.sessionStorage.getItem("alreadyShown")){
            setShowUp(true);
            window.sessionStorage.setItem("alreadyShown", true)
        }
        
    }, []);

    return(
        <PoupUpMain display={showUp}>
            <PoupUpCard>
                <h2>Que bom ter você aqui!</h2>
                <h3>Gostariamos de informar que o presente site ainda encontra-se em construção. Obrigado!</h3>
                <ConstructionSvg/>

                <button onClick={()=>setShowUp(!showUp)} className="btn">continuar</button>
            </PoupUpCard>
        </PoupUpMain>
    )
}

export default memo(PoupUp);