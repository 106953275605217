import { EnvaironmentBody, EnvaironmentMain, MainContent } from "./style";
import Header from "../../common/patters/header/index";
import Footer from "../../common/patters/footer/index";

export default function PageEnvaironment(){

    return(
        <EnvaironmentBody>
            <Header className="header"/>
            
            <EnvaironmentMain>

                <div className="title">
                    <h2 className="title">Lideres mundiais de consumo e potenciais energéticos</h2>
                    <span className="underLine"> </span>
                </div>
            

                <MainContent>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus, ligula in imperdiet fermentum, ligula nisl consequat metus, tristique sodales magna magna eu velit. Nam aliquam, nisi at tincidunt consequat, sem justo varius dolor, vitae rutrum sapien tellus sed mi. Vestibulum faucibus fermentum neque, ac sollicitudin mi rutrum quis. Nulla sit amet augue nisi. Nunc nec tellus ante. Nulla id purus ex. Curabitur blandit nisi urna, ut vehicula tellus rhoncus at. Sed vitae enim nisi. Nullam sit amet sagittis mi. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas ornare metus nec ullamcorper mattis.
                    <br/><br/>
                    Fusce ipsum arcu, tincidunt sed hendrerit et, rhoncus non eros. Phasellus sed faucibus ex, eget facilisis dolor. Nam auctor suscipit leo, quis lacinia magna vulputate in. Etiam venenatis pretium luctus. Nunc tempor ac nulla non gravida. Curabitur tincidunt mi quis urna viverra blandit. Mauris consectetur eleifend neque ut molestie. Mauris dignissim eu libero in consectetur. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris neque eros, suscipit vitae gravida vitae, volutpat volutpat purus. Cras pretium nibh sagittis nulla convallis vestibulum. Nulla massa est, posuere sed lacus in, interdum aliquet magna.</p>
                    <iframe src="https://www.youtube.com/embed/fyqDC_AKVgE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </MainContent>
            </EnvaironmentMain>

            <Footer className="footer"/>
        </EnvaironmentBody>
    )
}