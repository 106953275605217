import { useState, useEffect } from "react";
import { primary } from "../foundation/variables";

export default function useSwitchMenu(initial){
    const[currentItem, setCurrentItem] = useState("history");
    const[eventBefore, setEventBefore] = useState();

    useEffect(()=>{
        initialization(initial)
    }, []);
    

    function selectItem(event){
        if(eventBefore){
            eventBefore.classList.remove("select");
        }
        setEventBefore(event.target);
        
        setCurrentItem(event.target.id)
        event.target.classList.add("select");
    }

    function initialization(element){
        setEventBefore(element.current);
        element.current.classList.add("select");
    }

    return[selectItem, currentItem]

}