import { createGlobalStyle } from "styled-components";
import { backgroundColor, primary, secondary, tabletScreenMaxWidth } from "./variables";

const GlobalStyle = createGlobalStyle`
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        text-decoration: none;
        font-family: 'Roboto', sans-serif;
        list-style : none;
    }

    *::selection{
        background: ${secondary};
        color: #fff;
    }

    body{
        background: ${backgroundColor};
        font-size: 2.5vw;

        @media screen and (min-width: ${tabletScreenMaxWidth}px){
            font-size: 1.25vw;
        }
    }

    h1, h2, h3, h4, h5, h6{
        font-family: 'Open Sans', sans-serif;
    }

    h1{
        font-size: 7vw;

        @media screen and (min-width: ${tabletScreenMaxWidth}px){
            background: ${primary};
            color: #fff;
            font-size: 3.5vw;
        }
    }

    h2{
        font-size: 6vw;

        @media screen and (min-width: ${tabletScreenMaxWidth}px){
            font-size: 3vw;
        }
    }

    h3{
        font-size: 5vw;
        @media screen and (min-width: ${tabletScreenMaxWidth}px){
            font-size: 2.5vw;
        }
    }

    h4{
        font-size: 4vw;
        @media screen and (min-width: ${tabletScreenMaxWidth}px){
            font-size: 2vw;
        }
    }

    h5{
        font-size: 4vw;
    }

    h6{
        font-size: 3vw;
    }

    @keyframes showUp{
        from{
            transform: translateY(150%);
            opacity:0;
        }
        to{
            transform: translateY(0);
            opacity:1;
        }
    }

    @keyframes showDown{
        from{
            transform: translateY(-150%);
            opacity:0;
        }
        to{
            transform: translateY(0);
            opacity:1;
        }
    }

    @keyframes showRight{
        from{
            transform: translateX(-150%);
            opacity:0;
        }
        to{
            transform: translateX(0);
            opacity:1;
        }
    }

    @keyframes showLeft{
        from{
            transform: translateX(150%);
            opacity:0;
        }
        to{
            transform: translateX(0);
            opacity:1;
        }
    }

    @keyframes write{
        10%{
            opacity: 1;
        }
        100%{
            width: 100%;
            opacity: 1;
        }
    }

    @keyframes fadeIn{
        100%{
            opacity: 1;
        }
    }

    @keyframes zoomInSvgMobile{
        100%{
            opacity: 1;
            width: 100%;
            height: auto;
        }
    }

    @keyframes zoomInSvgDesktop{
        100%{
            opacity: 1;
            width: auto;
            height: 100%;
        }
    }

    @keyframes goUfo{
        10%{
            width: 15%;
            top: 50%;
            left: 50%;
            opacity: 1;
        }
        20%{
            width: 10%;
            top: 25%;
            left: 75%;
            opacity: 1;
        }
        30%{
        }
        40%{
            width: 10%;
            top: 50%;
            left: 75%;
            opacity: 1;
        }
        50%{
            width: 2.5%;
            top: 75%;
            left: 90%;
            opacity: 0.5;
        }
        60%{
            
            width: 2%;
            top: 90%;
            left: 0%;
            opacity: 0.5;
        }
        70%{

            
        }
        80%{
            width: 10%;
            top: 50%;
            left: 10%;
            opacity: 1;
        }
        90%{
            
        }
        100%{
            opacity: 0.2;
        }
    }
`;

export default GlobalStyle;