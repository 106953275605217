import { useState, useRef, useContext, useEffect } from "react";
import useSwitchMenu from "../../common/hooks/useSwitchMenu";
import Header from "../../common/patters/header";
import { CompanyBody, CompanyContent, CompanyMain, CompanyMenu } from "./style";
import contentJson from "./content.json"
import { LanguageContext } from "../../common/contexts/languageContext";
import Footer from "../../common/patters/footer";


export default function PageCompany(){
    const initial = useRef(null);
    const [selectItem, currentItem] = useSwitchMenu(initial);
    const {language} = useContext(LanguageContext);
    const [content, setContent] = useState(contentJson[language])

    useEffect(()=>{
        setContent(contentJson[language]);
    }, [language]);

    return(
        <CompanyBody>
                <span className={`bg ${content[currentItem].id}`}> </span>
                    <Header className="header"/>
            <CompanyMain>
                <CompanyMenu>
                    <ul className="list">
                        {
                            Object.values(content).map((item, index)=>{
                                if(index == 0){
                                    return(
                                        <li ref={initial} key={item.id}onClick={selectItem} id={item.id}>{item.menu}</li>
                                    )
                                }
                                return(
                                    <li onClick={selectItem} key={item.id} id={item.id}>{item.menu}</li>
                                )
                                })
                        }
                    </ul>
                </CompanyMenu>

                <CompanyContent>
                    <div className="title">
                        <h2>{content[currentItem].title}</h2>
                        <span className="underLine"> </span>
                    </div>
                    <p>{content[currentItem].description}</p>
                </CompanyContent>
            </CompanyMain>
            <Footer className="footer"/>
        </CompanyBody>
    )
}