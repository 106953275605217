import styledComponents from "styled-components";
import { secondary, tabletScreenMaxWidth } from "../../common/foundation/variables";

export const ContactBody = styledComponents.section`
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;

    overflow-x: hidden;
`; 

export const ContactMain = styledComponents.main`
    width: 100%;
    height: 100%;
    padding: 2.5vh 5vw;
    
    .title{
        position: relative;
        
        h2{
            height: 7.5%;
            margin-bottom: 1vh;
        }
        
        .underLine{
            width: 30%;
            border-top: 2px solid #000000;
            position: absolute;
            bottom:0;
            left: 0.25vw;
        }
    }

    a{
        font-size: 3.5vw;
        opacity: 0;
        animation: 1s showRight 1s forwards;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 15vh 0;
        gap: 2.5vh;

        .title{
            width: 60%;
            
            h2{
                height: auto;
                text-align: left;
                width: 100%;
                color: #000;
            }
        }


        h4{
            font-weight: 400;
        }

        a{
            font-size: 1.25vw;
            cursor: pointer;
            transition: 0.5s;

            :hover{
                font-size: 1.5vw;
                color: ${secondary};
            }
        }
    }
`;

export const ContactContent = styledComponents.section`
    display:flex;
    flex-direction: column;
    height: 90%;

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        flex-direction: row;
        height: auto;
        width: 60%;
        justify-content: space-between;
    }
`;

export const ContactAddress = styledComponents.section`
    display: flex;
    flex-direction:column;

    address{
        display: flex;
        flex-direction: column;
        gap: 2vh;
        margin-bottom: 2vh;
        
    }
    
    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        height: 100%;
        width: 50%;
        
        address{
            align-items: start;
            text-align: left;
            gap: 4vh;
            margin-bottom: 0;
            height: 100%;
            justify-content: start;

            .subTitle{
                font-weight: 600;
                font-size: 1.5vw;
            }
        }
    }
`;

export const ContactMap = styledComponents.section`
    display: flex;
    flex-direction:column;
    height: 100%;

    iframe{
        border: none;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 10px rgba(0,0,0,0.4);
        border-radius: 5px;
        margin-top : 1vh;
        opacity: 0;
        animation: 1s showLeft forwards;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        width: 40%;

    }
`;