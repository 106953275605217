import { HeaderBox1, HeaderBox2, HeaderContainer } from "./style";
import Logo from "../../components/logo/index.js";
import Language from "../../components/language";
import { Link } from "react-router-dom";
import contentJson from "./content.json";
import { useContext, useEffect, useState, memo } from "react";
import { LanguageContext } from "../../contexts/languageContext";

function Header(){
    const {language} = useContext(LanguageContext);
    const [content, setContent] = useState(contentJson[language]);

    useEffect(()=>{
        setContent(contentJson[language])
    }, [language]);

    return(
        <HeaderContainer>
            <HeaderBox1>
                <div className="logo" >
                    <Logo/>
                </div>
                <nav className="nav">
                    {
                     Object.values(content).map((item, index)=>(
                        <Link key={index} to={`${item.path}`}>{item.name}</Link>
                     ))   
                    }
                </nav>
                <div className="language">
                    <Language/>
                </div>
            </HeaderBox1>

            <HeaderBox2>
                <nav className="nav">
                    {
                     Object.values(content).map((item, index)=>(
                        <Link key={index} to={`${item.path}`}>{item.name}</Link>
                     ))   
                    }
                </nav>
            </HeaderBox2>
        </HeaderContainer>
    )
}

export default memo(Header);