import { HashRouter, Route, Routes } from 'react-router-dom';
import LanguageProvider from './common/contexts/languageContext';
import GlobalStyle from './common/foundation/globalStyle';
import PageCompany from './pages/company';
import PageConstruction from './pages/construction';
import PageContact from './pages/contact';
import PageEnvaironment from './pages/envaironment';
import PageError404 from './pages/error404';
import PageHome from './pages/home';
import PageTeam from './pages/team';
import PageTest from './pages/test/test';
import PoupUp from "../src/common/components/AlertPoupUp/index";
import { useEffect } from 'react';

function App() {
  return (
    <HashRouter>
      <GlobalStyle/>
      <PoupUp/>
      <LanguageProvider>
        <Routes>
          <Route path="/" element={<PageHome/>} />
          <Route path='/company' element={<PageCompany/>}/>
          <Route path='/team' element={<PageTeam/>}/>
          <Route path='/environment' element={<PageEnvaironment/>}/>
          <Route path='/contact' element={<PageContact/>}/>
          <Route path='/test' element={<PageTest/>}/>
          <Route path='*' element={<PageError404/>}/>
        </Routes>
      </LanguageProvider>
    </HashRouter>
  );
}

export default App;
