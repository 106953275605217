import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../common/contexts/languageContext";
import Footer from "../../common/patters/footer";
import Header from "../../common/patters/header";
import { ContactAddress, ContactBody, ContactContent, ContactMain, ContactMap } from "./style";
import contentJson from "./content.json";

export default function PageContact(){
    const {language} = useContext(LanguageContext);
    const [content, setContent] = useState(contentJson[language]);

    useEffect(()=>{
        setContent(contentJson[language]);

    }, [language]);

    return(
        <ContactBody>
            <Header/>

            <ContactMain>
                <div className="title">
                    <h2>{content.title}</h2>
                    <span className="underLine"> </span>
                </div>
                
                <ContactContent>
                    <ContactAddress>
                        
                        <address>
                            <div>
                                <p className="subTitle">E-MAIL:</p>
                                <a>contato@construsea.com</a>
                            </div>

                            <div>
                                <p className="subTitle">{content.phone}:</p>
                                <a>+5527912345678</a>
                            </div>

                            <div>
                                <p className="subTitle">{content.address}</p>
                                <a>R. Castorina Pinto Vieira, 256 - Cristóvão Colombo, Vila Velha - ES, 29106-560</a>
                            </div>
                        </address>
                    </ContactAddress>

                    <ContactMap>
                        <h4>{content.sub2}</h4>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3741.007270234411!2d-40.306816342513!3d-20.341318330276724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xb8161f2feb9f17%3A0x67b5fedd08cfcc76!2sR.%20Castorina%20Pinto%20Vieira%2C%20256%20-%20Crist%C3%B3v%C3%A3o%20Colombo%2C%20Vila%20Velha%20-%20ES%2C%2029106-560!5e0!3m2!1spt-BR!2sbr!4v1651091344281!5m2!1spt-BR!2sbr"title="Construsea engenharia" allowFullScreen={true} ></iframe>
                    </ContactMap>
                </ContactContent>
            </ContactMain>

            <Footer/>
        </ContactBody>
    )
}