import styledComponents from "styled-components"

const SvgBox = styledComponents.span`
    svg{
        transition: .5s;
        
        :active{
            transform: scale(0.5);
        }
    }
`;

export default function BtnOff({...props}){
    return(
        <SvgBox>
        <svg viewBox="0 0 86 86" fill={props.fill || "#ED3237"} xmlns="http://www.w3.org/2000/svg">
            <path d="M43.0005 46.3079C44.7924 46.3079 46.3436 45.6546 47.652 44.3442C48.9608 43.0356 49.6168 41.4842 49.6168 39.693V6.61575C49.6168 4.82365 48.9615 3.27339 47.652 1.96479C46.3438 0.65475 44.7924 0 43.0005 0C41.2084 0 39.6582 0.655474 38.3488 1.96479C37.0395 3.27339 36.3848 4.82437 36.3848 6.61575V39.6932C36.3848 41.4844 37.0395 43.0356 38.3488 44.3443C39.6582 45.6546 41.209 46.3079 43.0005 46.3079Z"/>
            <path d="M78.5331 28.5807C75.7589 23.0339 71.8574 18.3822 66.8272 14.627C65.3801 13.5245 63.7435 13.0937 61.9174 13.3347C60.0911 13.576 58.644 14.4372 57.576 15.9184C56.4734 17.3652 56.0522 18.9932 56.3099 20.8021C56.5675 22.6119 57.4212 24.0672 58.8683 25.1698C62.2434 27.7192 64.855 30.8379 66.6974 34.5245C68.5409 38.2108 69.4616 42.1391 69.4616 46.3073C69.4616 49.8917 68.7639 53.3106 67.3688 56.5672C65.9739 59.8232 64.0869 62.6393 61.7092 65.017C59.3315 67.394 56.5161 69.2802 53.2595 70.6759C50.0035 72.0716 46.5839 72.7703 43.0006 72.7703C39.4178 72.7703 35.998 72.0716 32.742 70.6759C29.4856 69.2802 26.6693 67.394 24.2916 65.017C21.915 62.6393 20.0284 59.8234 18.6327 56.5672C17.2374 53.3106 16.5396 49.8917 16.5396 46.3073C16.5396 42.1391 17.4614 38.2114 19.3045 34.5245C21.1478 30.8382 23.7578 27.7198 27.1342 25.1698C28.5812 24.0664 29.4337 22.6114 29.6925 20.8021C29.9508 18.9939 29.5291 17.3652 28.4263 15.9184C27.3581 14.4372 25.9202 13.5758 24.111 13.3347C22.3018 13.0935 20.6562 13.5245 19.1749 14.627C14.144 18.3822 10.2426 23.0339 7.46849 28.5807C4.69454 34.1283 3.30811 40.0371 3.30811 46.3075C3.30811 51.6832 4.35911 56.8164 6.46057 61.7091C8.56276 66.6017 11.3876 70.8216 14.937 74.3711C18.4854 77.9196 22.7062 80.7457 27.5992 82.8455C32.4918 84.9481 37.6256 86 43.0004 86C48.3754 86 53.509 84.9481 58.4018 82.8455C63.2949 80.745 67.5156 77.9194 71.0645 74.3711C74.6129 70.8227 77.4377 66.6017 79.5395 61.7091C81.6413 56.8164 82.6914 51.682 82.6914 46.3075C82.6916 40.0369 81.307 34.1281 78.5331 28.5807Z"/>
        </svg>
        </SvgBox>
    )
}