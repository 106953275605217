import { useState } from "react";

export default function useSwitchPerson(){
    const [personSelected, setPersonSelected] = useState(false);

    function switchPerson(ref){
        if(personSelected && personSelected != ref.current){
            personSelected.classList.remove("selected");
        }
        
        ref.current.classList.toggle("selected");

        if(personSelected == ref.current){
            setPersonSelected(false)
        }else{
            setPersonSelected(ref.current)
        }
    }

    return [personSelected, switchPerson];
}