import { FooterContainer } from "./style";
import {ReactComponent as Wind} from "../../../images/footerWind.svg";
import { memo } from "react";


function Footer(){

    return(
        <FooterContainer>
            <div className="contactData">
                <p>contact@construsea.com</p>
            </div>
            
            <div className="copyRight">
                <p>Created by <a href="https://sipione.tech" target="_blank" rel="noreferrer">Sipione Tech</a></p>
            </div>
        </FooterContainer>       
    )
}

export default memo(Footer);