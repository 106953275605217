import styled from "styled-components";
import { primary, secondary, tabletScreenMaxWidth } from "../../common/foundation/variables";
import mobileBg from "../../images/bgHomeMbl.webp"

export const HomeMain = styled.section`
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    background: rgba(0,0,0,0.6);
    position: relative;
    padding: 2.5vh 2.5vw;
    transition: .5s;


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    ::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        background: url(${mobileBg}) center / cover no-repeat;
        z-index: -1;
    }

    .desktopBg{
        display: none;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        overflow: hidden;

        .desktopBg{
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            overflow: hidden;

            video{
                min-height: 100vh;
                min-width: 100vw;
                object-fit: fill;
            }
        }

        background: none;
        ::before{
            display: none;
        }
    }
`;

export const HomeHeader = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .logo{
        width: 75vw;
        opacity: 0;
        animation: 1s showUp forwards;
    }

    .language{
        width: 18vw;
        z-index: 1;
    }

    p{
        text-align: center;
        padding: 5%;
        color: #fff;
        font-size: 4vw;
        transition: 0.5s;

        opacity:0;
        width: 0;
        white-space: nowrap;
        overflow: hidden;
        animation: 1s write 1s steps(50) forwards;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        width: 100%;
        height: 60%;

        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        .logo{
            width: 60%;
        }
    
        .language{
            width: 10vw;
            position: absolute;
            right: 2.5vw;
            top: 5vh;
        }

        p{
            text-align: center;
            padding: 0;
            color:${primary};
            font-size: 2vw;
        }
    }

`;

export const HomeShyElement = styled.div`
    transition: 0.5s;
    opacity: 0;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 15px 30px rgba(0,0,0,0.5);
    width: 60%;
    background: rgba(0, 36, 125, 0.6);

    :active{
        box-shadow: none;
        transform: scale(0.5);    
    }

    a{
        padding: 5%;
    }
    
    h2{
        color: #fff;
        font-size: 8vw;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        display: none;
    }
`;

export const BtnOnOff = styled.div`
    display: flex;
    flex-direction: column;
    width: 25vw;
    justify-self: end;

    .turn{
        color: white;
        text-align: center;
        font-size: 8vw;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        display: none;
    }
`;

export const HomeNavigation = styled.div`
    display: none;
    
    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        width: 100%;
        height: 40%;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: end;

        opacity: 0;
        animation: 1s showRight forwards;

        nav{
            width: 100%;
            
            padding: 2.5vw 5vh;
            border-radius:5px;
            display: flex;
            justify-content: space-evenly;

            a{
                color: #fff;
                font-size: 1.5vw;
                transition: 0.5s;
            }

            a:hover{
                color: ${secondary};
                transform: scale(1.1);
            }
        }
    }
`;