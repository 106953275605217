import styledComponents from "styled-components";
import { primary, tabletScreenMaxWidth } from "../../common/foundation/variables";
import team01 from "../../images/team01.webp";
import team02 from "../../images/team02.webp";


export const TeamBody = styledComponents.section`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: transparent;
`;

export const TeamContainer = styledComponents.main`
    padding: 2.5vh 5vw 0 5vw;
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    .title{
        position: relative;
        margin-left: 5vw;
        
        h2{
            height: 7.5%;
            margin-bottom: 1vh;
            
            opacity:0;
            animation: 1s showDown forwards;
        }
        
        .underLine{
            width: 60%;
            border-top: 2px solid #000000;
            position: absolute;
            bottom:0;
            left: 0.25vw;
        }
    }

    .flexContent{
        width: 100%;
        height:100%;
        display: flex;
        flex-direction: column;

        svg{
            position: absolute;
            bottom: 0;
            left:50%;
            transform: translate(-50%, 0);
            z-index: -1;
            
            height: 0;
            width: 0;
            animation: 1s zoomInSvgMobile forwards; 
        }
    }
    
    

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        justify-self: stretch;
        justify-content: space-between;
        align-items: start;
    

        .flexContent{
            flex-direction: row;

            svg{
                height: 0;
                width: 0;

                animation: 1s zoomInSvgDesktop forwards;
            }
        }


        
    }
`;

export const TeamBox1 = styledComponents.div`
    width: 100%;
    height: 30%;
    display: flex; 
    justify-content: space-evenly;
    align-items: center;   

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        flex-direction: column;
        height: auto;
        width: 20%;
    }
`;

export const CardPerson = styledComponents.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    
    
    .img01, .img02{
        width: 100px;
        height: 100px;
        filter: grayscale(100) blur(1px);
    }
    
    .img01{
        background: url(${team01}) center / 80% no-repeat;

        opacity: 0;
        animation: 1s showRight forwards;
    }

    .img02{
        background: url(${team02}) center / 80% no-repeat;

        opacity: 0;
        animation: 1s showRight .5s forwards;
    }

    h4{
        opacity: 0;
        animation: 1s showUp 1s forwards;
    }

    .selected{
        filter: none;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        transition: 0.5s;
        cursor: pointer;

        :hover{
            transform: scale(1.2);
            filter: drop-shadow(0 10px 10px rgba(0,0,0,0.4));

            .img01, .img02{
                filter: none;
            }
        }

        h4{
            font-size: 1.5vw;
            text-align: center;
        }
    }
`;

export const TeamBox2 = styledComponents.div`
    height: 70%;
    display: flex;
    flex-direction: column;

    .title2{
        font-size: 6vw;
    }
    .person01{
        background: url(${team01}) bottom right / 60% no-repeat;
    }

    .person02{
        background: url(${team02}) bottom right / 50% no-repeat;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        width: 80%;
        height: 100%;
        min-height: 72.5vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        
        .title2{
            font-size: 3vw;
            width: 60%;
            align-self: end;
            text-align: left;
            color: ${primary};
        }

        .person01{
            background: url(${team01}) bottom left / contain no-repeat;
        }
    
        .person02{
            background: url(${team02}) bottom left / contain no-repeat;
        }
    }

`;

export const TeamContent = styledComponents.div`
    display: flex;
    justify-content: space-between;
    height:100%;
    
    .text{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap:  2.5vh;
    }
    
    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        width:100%;
        height: 100%;
        justify-content: end;
        padding: 5vh 0;

        .text{
            width: 60%;
        }

    }
    
`;