import styledComponents from "styled-components";
import { primary, tabletScreenMaxWidth } from "../../foundation/variables";


export const LanguageBox = styledComponents.div`
    width: 100%;
    select{
        width: 100%;
        background: transparent;
        border: none;
        outline: none;
        color: ${primary};
        padding: 5%;
    }
    
    option{
        background: transparent;
        color: ${primary};
        border: none;
        outline: none;
    }
    
    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        
        select{
            font-size: 1.65vw;
        }
    }
`;