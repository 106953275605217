import styledComponents from "styled-components";
import { primary, secondary, tabletScreenMaxWidth } from "../../common/foundation/variables";



export const ErrorBody = styledComponents.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10vh 10vw;;

    height: 100vh;
    overflow: hidden;
    position: relative;

    .background{
        position: absolute;
        left: -100%;
        bottom: 0;
        height: 100vh;
        opacity: 0.75;    
    }

    .ufo{
        width: 5%;
        height: auto;
        z-index: 1;
        transition: .5s;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        animation: 15s goUfo infinite alternate;
    }


    h1, h2, a{
        color: #fff;
        z-index: 2;
        text-align: center;
    }
    
    h1{
        font-size: 12vw;
    }

    h2, a{
        font-size: 8vw;
    }

    a{
        color: ${secondary};
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        justify-content: start;
        padding: 2.5vh 5vw;

        .background{
            left: 0;
            top: 0;
            width: 100vw;
            height: auto;    
        }

    
        h1{
            font-size: 6vw;
            background: none;
        }
    
        h2{
            font-size: 4vw;
        }
    
        a{
            font-size: 3vw;
            color: ${secondary};
            transition: 0.5s;

            :hover{
                color: ${primary};
                font-size: 4vw;
            }
        }

    }
`;

export const ErrorMain = styledComponents.main`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;