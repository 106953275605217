import styledComponents from "styled-components";
import { backgroundColor, primary, secondary, tabletScreenMaxWidth } from "../../foundation/variables";


export const PoupUpMain = styledComponents.section`
    position: fixed;
    z-index: 5;
    top:0;
    left:0;
    width: 100vw;
    height:100vh;
    background: rgba(0,0,0,0.85);
    backdrop-filter: blur(10px);
    
    display: ${props=>props.display ? "flex" : "none"};
    justify-content: center;
    align-items: center;
`;

export const PoupUpCard = styledComponents.div`
    width: 90%;
    height: 50%;
    background: ${backgroundColor};
    border-radius: 5px;
    box-shadow: 0 0 20px ${primary};
    position: relative;
    overflow: hidden;
    color:${primary};
    padding: 2.5%;
    z-index: -2;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 5vh;

    svg{
        position: absolute;
        bottom: 0;
        left: 0;
        fill: ${secondary};
        opacity: 0.4;
        z-index:-1;
    }

    .btn{
        padding: 2.5%;
        border-radius: 5px;
        background: ${backgroundColor};
        z-index: 2;
        font-size: 5vw;
        cursor: pointer;
        transition: 0.5s;
        border: 1px dashed ${secondary};
        width: auto;
        align-self: center;
        margin-top: 10vh;

        :hover{
            background: ${primary};
            color: #fff;
        }
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        width: 50%;
        justify-content: space-between;
        gap: 0;

        .btn{
            align-self: end;
            padding: 1.25%;
            font-size: 1.25vw;
            margin-top: 0vh;
        }
    }
`;