import styledComponents from "styled-components";
import { backgroundColor, primary, secondary, tabletScreenMaxWidth } from "../../foundation/variables";


export const FooterContainer = styledComponents.footer`
    width: 100vw;
    color: ${primary};
    z-index: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
    div{
        display: flex;
    }    

    .contactData{
        display: none;
    }
    
    .copyRight{
        width: 100%;
        align-itens: center;
        justify-content: center;
        background: ${primary};
        color: #fff;
        padding: 1% 0;
        
        a{
            color: ${secondary};
            transition: 0.5s;
            
            :hover{
                color: #fff;
            }
        }
    }
    
    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        padding: 0 2.5vw;
        width: 100%;
        max-height: 5vh;
        flex-wrap: no-wrap;
        justify-content:space-between;


        div{
            display: flex;
        }
    
        .contactData{
            display: flex;
        }
    
        .copyRight{
            width: auto;
            align-itens: start;
            justify-content: end;
            background: none;
            color: ${primary};
            padding: 0;
            
            a{
                color: ${secondary};
                transition: 0.5s;
                
                :hover{
                    color: ${primary};
                    
                }
            }
        }
    }
`;