import { ErrorBody } from "./style";
import {ReactComponent as Background} from "../../images/errorBg.svg";
import {ReactComponent as ErrorUfo} from "../../images/errorUfo.svg";
import { Link } from "react-router-dom";


export default function PageError404(){

    return(
        <ErrorBody>
            <h1>ERROR 404</h1>

            <h2>
                Nothing was found here.
                <br/>
                <Link to="/">Let's back to the light!</Link>
            </h2>
            <ErrorUfo className="ufo"/>            

            <Background className="background"/>
        </ErrorBody>
    )
}