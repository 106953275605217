import { LanguageBox } from "./style";
import { LanguageContext } from "../../contexts/languageContext";
import { useContext, useEffect } from "react";


export default function Language(){
    const {language, setLanguage} = useContext(LanguageContext);

    useEffect(()=>{
        const item = document.getElementById(language)
        item.setAttribute("selected", "selected");
    }, []);

    function toggleFlag(event){
        setLanguage(event.target.value);
        event.target.checked = true;
        console.log(event)
    }

    return(
        <LanguageBox>
            <select onChange={toggleFlag}className="languageSelect">
                <option id="pt-br" value="pt-br" className="br">PT-BR</option>
                <option id="en-us" value="en-us">EN-US</option>
            </select> 
        </LanguageBox>
    )
}