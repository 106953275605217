import styledComponents from "styled-components";
import { primary, secondary, tabletScreenMaxWidth } from "../../foundation/variables";


export const HeaderContainer = styledComponents.header`
    width: 100%;
    height: 10vh;
    max-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
    z-index: 1;
    
    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        padding: 5vh 2.5vw;
        flex-direction: row;
        gap: 0;
    }
`;

export const HeaderBox1 = styledComponents.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2.5vh 5vw 0 5vw;

    .logo{
        width: 60%;
    }

    .nav{
        display: none;
    }

    .language{
        width: 20%;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        padding: 0;

        align-items: center;

        .logo{
            width: 35%;
        }

        .nav{
            width:60%;
            display: flex;
            justify-content: space-evenly;

            a{
                color: ${primary};
                font-size: 1.65vw;
                transition: 0.5s;
            }
            a:hover{
                color:${secondary};
                transform: scale(1.1);
                text-shadow: 0 5px 10px rgba(0,0,0,0.6);
            }
        }

        .language{
            width: 10%;
        }
    }
`;

export const HeaderBox2 = styledComponents.div`
    width: 100vw;
    background: ${primary};
    padding: .5vh 0;
    display: flex;
    justify-content: center;

    .nav{
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        a{
            font-size: 3.25vw;
            color: #fff;
        }
    }
    @media screen and (min-width: ${tabletScreenMaxWidth}px){

        display: none;
    }
`;