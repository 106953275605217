import {useContext, useRef, useEffect, useState} from "react";
import Logo from "../../common/components/logo";
import { BtnOnOff, HomeGifContainer, HomeHeader, HomeMain, HomeNavigation, HomeShyElement } from "./style";
import BtnOff from "../../common/components/buttons/btnSvg";
import useToggleonOff from "../../common/hooks/useToggleOnOff";
import Language from "../../common/components/language";
import desktopBg from "../../images/bgHomeDsk_Trim.mp4";
import { Link } from "react-router-dom";
import contentJson from "./content.json"
import { LanguageContext } from "../../common/contexts/languageContext";


export default function PageHome(){
    const [fill, turn, toggle] = useToggleonOff();
    const shyElement = useRef();
    const bgHome = useRef();
    const slogan = useRef();
    const {language} = useContext(LanguageContext)
    const [content, setContent] = useState(contentJson[language]);

    useEffect(()=>{
        setContent(contentJson[language]);
    }, [language])

    return(
        <HomeMain ref={bgHome} className="on">
            <div className="desktopBg">
                <video muted autoPlay="autoplay" loop={true} controls={false}  src={desktopBg}/>
            </div>
            <HomeHeader>
                <div className="logo">
                    <Logo/>
                </div>
                <div className="language">
                    <Language/>
                </div>

                <p ref={slogan}>
                    {content["slogan"]}
                </p>
            </HomeHeader>
             
             <HomeShyElement ref={shyElement} >
                <Link to="/company"><h2>
                    {content.shyContent}
                </h2></Link>
             </HomeShyElement>

             <BtnOnOff onClick={event => toggle(event, slogan, shyElement, bgHome)}>
                <BtnOff fill={fill}/>
                <p className="turn">{turn}</p>
             </BtnOnOff>

             <HomeNavigation>
                 <nav>
                     {
                         content.nav.map((i, index)=>(
                            <Link to={i.path} key={index}>{i.name}</Link>
                         ))
                     }
                 </nav>
             </HomeNavigation>
        </HomeMain>
    )
}