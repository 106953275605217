import { useState } from "react";

export default function useToggleonOff(){
    const [fill, setFill] = useState(false);
    const [turn, setTurn] = useState("OFF");

    function toggle(event, slogan, text, bg){

        if(fill == false){
            setFill("lightgreen");
            setTurn("ON")
            text.current.style.opacity = 1;
            bg.current.style.background= "none";
            slogan.current.style.color ="#000";
        }else{
            setFill(false);
            setTurn("OFF");
            text.current.style.opacity = 0;
            bg.current.style.background = "rgba(0,0,0,0.6)";
            slogan.current.style.color ="#fff";
        }

        bg.current.classList.toggle("on");
    }
    return[fill, turn, toggle]
} 