import styledComponents from "styled-components";
import { primary, secondary, tabletScreenMaxWidth } from "../../common/foundation/variables";


export const TeamContainer = styledComponents.section`
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow-x: hidden;
    padding: 2.5vh 5vw;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: start;

    .header, .footer{
        width: 100%;
    }


    .footer{
        position: absolute;
        bottom: 0;
        left:0;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        padding: 5vh 2.5vw;

    }
`;

export const TeamBox1 = styledComponents.div`
    width: 100%;
    height: 75vh;
    display: flex; 
    justify-content: start;
    align-items: start;
    

    .contentText{
        width: 100%;
        padding: 0;
        color: ${primary};
        font-size: 10vw; 
    }

    svg{
        fill: ${secondary};
        max-height: 100vh;
        opacity: 0.6;
        z-index: -1;
        position: absolute;
        bottom: 7.5vh;
        right: 0;
    }

    @media screen and (min-width: ${tabletScreenMaxWidth}px){
        height: 80vh;
        padding-top: 10vh;

        svg{
            bottom: 0;
        }
        .contentText{
            width: 60%;
            font-size: 5vw;  
        }

    }
`;